import { ReactNode } from 'preact/compat';

type TrackingOverlayProps = {
    children: ReactNode;
};
export function TrackingOverlay({ children }: TrackingOverlayProps) {
    return (
        <>
            <div className="fixed z-[999] w-full h-full bg-[rgba(0,0,0,0.6)] flex flex-col-reverse animate-fade-in pointer-events-none">
                {children}
            </div>
        </>
    );
}
