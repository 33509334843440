import videoShaderFrag from '../shaders/videoShaderFrag.glsl';
import videoShaderVert from '../shaders/videoShaderVert.glsl';
import {
    Audio,
    Camera,
    Clock,
    LinearFilter,
    Mesh,
    Object3D,
    PlaneGeometry,
    RGBAFormat,
    Scene,
    ShaderMaterial,
    Vector2,
    Vector3,
    VideoTexture,
} from 'three';
import {
    ImageTargetView,
    artworkLoadingAtom,
    imageTargetViewAtom,
    imageTargetAtom,
    imageTargetStore,
    imageTargetVideo,
} from './8thwall/atoms';
import { MusicCentreImageTargetPipelineModuleResult } from './8thwall/music-centre-image-target-pipeline-module';
import {
    getArtworkTargetDimensions,
    scaleToCover,
    scaleToFit,
} from './utilities/utilities';
import { artworkData } from './artworks';
import { IArtwork } from './types';
import { I8thWallImageTargetEventModel } from './8thwall';
import ReactGA from 'react-ga4';

export function initImageTargetExperience(
    module: MusicCentreImageTargetPipelineModuleResult,
    scene: Scene,
    camera: Camera,
    audio: Audio,
) {
    enum ArtworkState {
        LOADING,
        PLAYING,
        STOPPED,
    }
    type ITargetType = I8thWallImageTargetEventModel['detail'] & {
        distanceToScreenCenter?: number;
    };
    let artworks: IArtwork[] = [];
    artworks = artworkData;

    const targetsFound: ITargetType[] = [];
    const targetPosition3D = new Vector3();
    const targetPosition2D = new Vector2();
    const screenCenter = new Vector2(0.5, 0.5);
    let artworkActive: IArtwork | null;
    let artworkActiveLostTime = 0.0;
    const artworkActiveLostTimeMax = 3.0;
    let bResetVideoAnimation = false;
    const bBlockVideoAnimation = false;

    let pauseRender = true;

    const clock = new Clock();

    const video = imageTargetStore.get(imageTargetVideo);
    // Add event listener to notify when video ends
    video.addEventListener('ended', () => {
        video.play();
    });
    const texture = new VideoTexture(video);
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.format = RGBAFormat;

    const uniforms = {
        videoTexture: { type: 't', value: texture },
        videoHasAlpha: { value: false },
        videoLoaded: { value: false },
        timeElapsed: { value: 0.0 },
        timeMark: { value: 0.0 },
    };

    const shaderMaterial = new ShaderMaterial({
        uniforms: uniforms,
        vertexShader: videoShaderVert,
        fragmentShader: videoShaderFrag,
        transparent: true,
    });

    const videoPlane = new PlaneGeometry(1, 1);
    const videoMesh = new Mesh(videoPlane, shaderMaterial);
    const videoContainer = new Object3D();
    videoContainer.visible = false;
    videoContainer.add(videoMesh);

    scene.add(videoContainer);

    imageTargetStore.set(imageTargetViewAtom, ImageTargetView.SCANNING);

    // link audio
    audio.setMediaElementSource(video);
    audio.hasPlaybackControl = true;

    const artworkStop = () => {
        video.pause();
        videoMesh.material.uniforms.videoLoaded.value = false;
        (videoMesh.material.uniforms.needsUpdate as unknown as boolean) = true;
    };

    const artworkPlay = async (artwork: IArtwork) => {
        imageTargetStore.set(imageTargetViewAtom, ImageTargetView.VIEWING);

        const { width: targetWidth, height: targetHeight } =
            await getArtworkTargetDimensions(artwork);

        video.src =
            artwork.files.find((file) => file.type === 'animation')?.path ?? '';

        video.load();
        video.oncanplay = () => {
            video.play();

            // Scale eyejack image target within 4:3 ratio (8thwall image target format)
            const targetDims = scaleToFit(
                new Vector2(targetWidth, targetHeight).normalize(),
                new Vector2(0.75, 1),
            );

            const { videoWidth, videoHeight } = video;
            // Scale video size to cover target
            const videoDims = scaleToCover(
                new Vector2(
                    artwork.animationHasAlpha ? videoWidth / 2 : videoWidth,
                    videoHeight,
                ).normalize(),
                targetDims,
            );

            videoMesh.scale.x = videoDims.x;
            videoMesh.scale.y = videoDims.y;

            videoMesh.material.uniforms.videoHasAlpha.value =
                artwork.animationHasAlpha;
            videoMesh.material.uniforms.videoLoaded.value = true;
            (videoMesh.material.uniforms.needsUpdate as unknown as boolean) =
                true;
            imageTargetStore.set(artworkLoadingAtom, false);
        };
        videoMesh.scale.x = 1.0;
        videoMesh.scale.y = 1.0;
        videoMesh.material.uniforms.timeMark.value = clock.getElapsedTime();
        (videoMesh.material.uniforms.needsUpdate as unknown as boolean) = true;

        imageTargetStore.set(artworkLoadingAtom, true);
    };

    module.emitter.on('resume-tracking', () => (pauseRender = false));
    module.emitter.on('pause-tracking', () => (pauseRender = true));

    module.emitter.on('on-update-scene', () => {
        if (pauseRender) return;
        for (let i = 0; i < targetsFound.length; i++) {
            const target = targetsFound[i];
            targetPosition3D.copy(target.position);
            targetPosition3D.project(camera);
            targetPosition2D.x = (targetPosition3D.x + 1) / 2;
            targetPosition2D.y = (-targetPosition3D.y + 1) / 2;
            target.distanceToScreenCenter =
                targetPosition2D.distanceTo(screenCenter);
        }

        targetsFound.sort((a, b) => {
            return a.distanceToScreenCenter && b.distanceToScreenCenter
                ? a.distanceToScreenCenter - b.distanceToScreenCenter
                : 0;
        });

        let targetFound = null;
        let artworkFound = null; // the artwork that is meant to be playing right now (can also be null if no artwork is found)
        if (artworkActive) {
            for (let i = 0; i < targetsFound.length; i++) {
                if (artworkActive.targetName === targetsFound[i].name) {
                    artworkFound = artworkActive;
                    targetFound = targetsFound[i];
                    break;
                }
            }
        }
        if (!artworkFound) {
            if (targetsFound.length > 0) {
                const targetFirst = targetsFound[0];
                for (let i = 0; i < artworks.length; i++) {
                    if (artworks[i].targetName === targetFirst.name) {
                        artworkFound = artworks[i];
                        targetFound = targetFirst;
                        break;
                    }
                }
            }
        }

        if (artworkActive != null) {
            if (artworkFound != null) {
                if (artworkActive.targetName == artworkFound.targetName) {
                    artworkActiveLostTime = 0.0;
                }
            } else {
                artworkActiveLostTime += clock.getDelta();
            }
        }

        let bReplace = false;
        if (artworkFound != null && artworkActive != null) {
            bReplace = artworkActive.targetName != artworkFound.targetName;
        }

        let bTimeout = false;
        if (artworkActiveLostTime >= artworkActiveLostTimeMax) {
            artworkActiveLostTime = artworkActiveLostTimeMax;
            bTimeout = true;
            imageTargetStore.set(imageTargetViewAtom, ImageTargetView.SCANNING);
        }

        let bRestart = false;
        bRestart = bRestart || bResetVideoAnimation;
        bRestart = bRestart || bBlockVideoAnimation;
        bResetVideoAnimation = false;

        const bStop =
            artworkActive != null && (bReplace || bTimeout || bRestart);
        if (bStop) {
            artworkStop();
            artworkActive = null;
        }

        let bPlay = true;
        bPlay = bPlay && artworkFound != null;
        bPlay = bPlay && artworkActive == null;
        bPlay = bPlay && bBlockVideoAnimation == false;
        if (bPlay) {
            artworkActive = artworkFound;
            artworkActiveLostTime = 0.0;
            if (!artworkActive) return;
            imageTargetStore.set(imageTargetAtom, artworkActive);
            ReactGA.event('artwork_viewed', {
                artwork_viewed: artworkActive.artist,
            });
            artworkPlay(artworkActive);
        }

        let bShowVideo = true;
        bShowVideo = bShowVideo && artworkActive != null;
        bShowVideo = bShowVideo && targetFound != null;
        if (bShowVideo) {
            if (!targetFound) return;
            videoContainer.position.set(
                targetFound.position.x,
                targetFound.position.y,
                targetFound.position.z,
            );
            videoContainer.quaternion.copy(
                targetFound.rotation as THREE.Quaternion,
            );
            videoContainer.scale.set(
                targetFound.scale,
                targetFound.scale,
                targetFound.scale,
            );
            videoContainer.visible = true;
        } else {
            videoContainer.visible = false;
        }
        videoMesh.material.uniforms.timeElapsed.value = clock.getElapsedTime();
        (videoMesh.material.uniforms.needsUpdate as unknown as boolean) = true;

        const bFade = false; // does not work - think it also needs to be hooked up to a button event.
        if (bFade) {
            if (video != null) {
                let volume =
                    1.0 - artworkActiveLostTime / artworkActiveLostTimeMax;
                volume = Math.floor(volume * 10) / 10.0;
                if (video.volume != volume) {
                    video.volume = volume;
                }
            }
        }
    });

    module.emitter.on('on-show-target', ({ detail }) => {
        targetsFound.push(detail);
    });

    module.emitter.on('on-update-target', ({ detail }) => {
        for (let i = 0; i < targetsFound.length; i++) {
            if (targetsFound[i].name == detail.name) {
                targetsFound[i] = detail;
                break;
            }
        }
    });

    module.emitter.on('on-hide-target', ({ detail }) => {
        for (let i = 0; i < targetsFound.length; i++) {
            if (targetsFound[i].name == detail.name) {
                targetsFound.splice(i, 1);
                break;
            }
        }
    });
}
