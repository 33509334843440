import { Modal } from '../../components/Modal';
import { ModalOverlay } from '../../components/ModalOverlay';
import DisplayImage from './assets/display-image.webp';
import { LogoMain } from '../../components/LogoMain';

type InfoModalProps = {
    onClose: () => void;
};

export const InfoModal = ({ onClose }: InfoModalProps) => {
    return (
        <ModalOverlay>
            <Modal
                maxWidth="max-w-[390px]"
                maxHeight="max-h-[638px]"
                className="bg-[#B6327D] bg-opacity-90 bg-cover text-white centered w-[92%] rounded-none z-[999]"
            >
                <button
                    className="absolute top-5 right-5 z-[100]"
                    onClick={onClose}
                >
                    <svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="1"
                            y="1"
                            width="32"
                            height="31.6079"
                            stroke="white"
                        />
                        <path
                            d="M17.1992 18.2596L13.1909 22.2188L11.918 20.9548L15.9195 17.0022L11.918 13.043L13.1909 11.7857L17.1992 15.7382L21.2008 11.7857L22.4737 13.043L18.4721 17.0022L22.4737 20.9548L21.2008 22.2121L17.1992 18.2596Z"
                            fill="white"
                        />
                    </svg>
                </button>
                <div className="flex flex-col items-center justify-between h-full w-full pt-5 pb-10 px-5">
                    <LogoMain className="self-start" />

                    {/* <div className="h-[1fr] flex flex-col items-center justify-center "> */}
                    <p className="text-lg self-start font-mark-pro-xtralite pb-2 leading-[125%]">
                        Discover upcoming events like this and other cultural
                        happenings at{' '}
                        <a
                            className="text-[#95D1F2] underline underline-offset-2 decoration-1 pointer-events-auto"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.musiccenter.org/?utm_source=eyejack&utm_medium=plaza&utm_campaign=tmchomepage"
                        >
                            The Music Center
                        </a>
                        .
                    </p>
                    <p className="text-lg self-start font-mark-pro-xtralite pb-2 leading-[125%]">
                        The artworks at The Music Center seen today are brought
                        to you by{' '}
                        <a
                            className="text-[#95D1F2] underline underline-offset-2 decoration-1 pointer-events-auto"
                            target="_blank"
                            rel="noreferrer"
                            href="https://eyejack.io"
                        >
                            EyeJack
                        </a>
                        , an Art and Technology company that creates
                        experiences.
                    </p>
                    <img className="w-full pb-2" src={DisplayImage} />
                    {/* </div> */}
                    <button
                        className="button-default max-w-full text-base"
                        onClick={() => {
                            window
                                .open('https://eyejack.io', '_blank')
                                ?.focus();
                        }}
                    >
                        VISIT EYEJACK
                    </button>
                </div>
            </Modal>
        </ModalOverlay>
    );
};
