type ErrorBoundaryProps = {
    onResetError: () => void;
    error: string;
};
export const ErrorBoundary = ({
    onResetError,
    error = '',
}: ErrorBoundaryProps) => {
    return (
        <div className="absolute inset-0 z-[1000] justify-between text-white h-full w-full bg-[#B6327D] flex items-center flex-col p-12">
            <div className="w-full h-full  flex flex-col justify-center items-center">
                <div>
                    <h2 className="text-[96px] text-center text-[#95D1F2] text-mark-pro ">
                        404
                    </h2>
                    <h2 className="text-3xl text-center font-mark-pro ">
                        Oops!
                    </h2>
                    <h2 className="text-xl text-center font-mark-pro ">
                        Something went wrong!
                    </h2>
                    <p className="text-lg text-center pt-4 font-mark-pro ">
                        Reason: {error ?? ''}
                    </p>
                </div>
            </div>
            <div className="w-full flex flex-col justify-end items-center gap-3">
                <button className="refraction-button pointer-events-auto button-default text-base">
                    <a
                        href="https://forms.gle/Qhr1NxWTa6tA3MQE9"
                        target="_blank"
                        rel="noreferrer"
                    >
                        REPORT BUG
                    </a>
                </button>
                <button
                    className="refraction-button pointer-events-auto button-default text-base"
                    onClick={onResetError}
                >
                    BACK TO EXPERIENCE
                </button>
            </div>
        </div>
    );
};
