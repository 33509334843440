import clsx from 'clsx';
import { useAtom } from 'jotai';
import {
    useEffect,
    useErrorBoundary,
    useMemo,
    useRef,
    useState,
} from 'preact/hooks';
import './app.css';
import { ExperienceType, experienceTypeAtom } from './atoms';
import { ErrorBoundary } from './components/ErrorBoundary';
import { GroundDetectionImg } from './components/GroundDetectionImg';
import { ImageTrackingImg } from './components/ImageTrackingImg';
import { FadeTransition } from './components/Transitions';
import ArtworkPopup from './features/artwork-popup';
import { InfoModal } from './features/info-modal';
import MediaPreview from './features/media-preview';
import { OnboardingModals } from './features/onboarding';
import { RecordingButton, useVideoRecorder } from './features/recording-button';
import Splash from './features/splash';
import TrackingOverlay from './features/tracking-overlay';
import useUserDevice from './hooks/useUserDevice';
import { RendererApi, initExperienceRenderer } from './renderer';
import {
    ImageTargetView,
    imageTargetAtom,
    imageTargetViewAtom,
} from './renderer/8thwall/atoms';
import { modelArtwork } from './renderer/artworks';
import WatermarkFile from '/watermark.png';

import InfoButtonImg from './assets/info-button.svg';
import useScreenOrientation from './hooks/useScreenOrientation';
import LandscapeOverlay from './features/landscape-overlay';
import { createPortal } from 'preact/compat';
import { LogoMain } from './components/LogoMain';
import { useEventListener } from 'usehooks-ts';

export function App() {
    const [error, resetError] = useErrorBoundary();

    const [renderer, setRenderer] = useState<RendererApi>();
    const [isLoadingExperience, setIsLoadingExperience] = useState(false);

    const { device, isMobile } = useUserDevice();
    const screenOrientation = useScreenOrientation();

    const initExperience = async () => {
        setIsLoadingExperience(true);
        const canvasEl = document.getElementById(
            'xr-canvas',
        ) as HTMLCanvasElement;
        if (!canvasEl) throw new Error('No Canvas element.');
        const renderer = await initExperienceRenderer(canvasEl, {
            watermarkImageUrl: WatermarkFile,
        });
        setRenderer(renderer);

        await new Promise((resolve) => setTimeout(resolve, 2000));

        setIsLoadingExperience(false);
        setShowOnboardingModal(true);
    };

    const resetErrors = () => {
        resetError();
        window.location.reload();
    };

    const [imageTarget, _setImageTarget] = useAtom(imageTargetAtom);
    const [experienceType, _setExperienceType] = useAtom(experienceTypeAtom);
    const [imageTargetView, _setimageTargetView] = useAtom(imageTargetViewAtom);

    const [showOnboardingModal, setShowOnboardingModal] = useState(true);
    const [showInfoModal, setShowInfoModal] = useState(false);

    const [progress, setProgress] = useState<{
        progress: number;
        total: number;
    }>({ progress: 0, total: 0 });

    const handleOnboardingClose = async () => {
        setShowOnboardingModal(false);
        setUiStatus('show');

        // setHasViewedOnboarding(true);

        // after 5 seconds remove overlay even if tracking is bad
        if (experienceType === ExperienceType.MODEL) {
            setTimeout(async () => {
                if (!hasShownOverlayOnce) {
                    setHasShownOverlayOnce(true);
                    setUiStatus('hide');
                    const model = await renderer?.loadArtwork();
                    if (model) {
                        model.scale.set(1, 1, 1);
                    } else {
                        console.warn('No model found');
                    }
                }
            }, 5000);
        }
    };

    const handleInfoClose = () => {
        setShowInfoModal(false);
    };

    /**
     * Tracking state
     */
    const [trackingStatus, setTrackingStatus] = useState<'show' | 'hide'>(
        'show',
    );
    const [uiStatus, setUiStatus] = useState<'show' | 'hide'>('hide');
    const [isCameraDown, setIsCameraDown] = useState(false);
    const recordingState = useVideoRecorder(renderer);

    const showTrackingOverlay = useMemo(() => {
        return (
            experienceType === ExperienceType.MODEL &&
            recordingState.state === 'none' &&
            !showOnboardingModal &&
            uiStatus === 'show' &&
            !showInfoModal
        );
    }, [
        recordingState,
        showOnboardingModal,
        experienceType,
        uiStatus,
        showInfoModal,
    ]);

    const showImageTrackingOverlay = useMemo(() => {
        return (
            experienceType === ExperienceType.IMAGES &&
            recordingState.state === 'none' &&
            !showOnboardingModal &&
            imageTargetView === ImageTargetView.SCANNING &&
            !showInfoModal
        );
    }, [
        recordingState,
        showOnboardingModal,
        imageTargetView,
        experienceType,
        showInfoModal,
    ]);

    const showImageTargetArtworkPopup = useMemo(() => {
        return (
            imageTarget &&
            !showImageTrackingOverlay &&
            recordingState.state === 'none' &&
            !showOnboardingModal &&
            !showInfoModal
        );
    }, [
        imageTarget,
        recordingState.state,
        showImageTrackingOverlay,
        showOnboardingModal,
        showInfoModal,
    ]);

    const showModelArtworkPopup = useMemo(() => {
        return (
            recordingState.state === 'none' &&
            !showOnboardingModal &&
            !showInfoModal &&
            !showTrackingOverlay
        );
    }, [
        recordingState.state,
        showOnboardingModal,
        showInfoModal,
        showTrackingOverlay,
    ]);
    const fadeRecording = useMemo(
        () => isCameraDown && recordingState.state === 'none',
        [isCameraDown, recordingState.state],
    );
    const showRecording = useMemo(() => {
        return (
            recordingState.state !== 'ready' &&
            !showOnboardingModal &&
            !showImageTrackingOverlay &&
            !showTrackingOverlay &&
            !showInfoModal &&
            !fadeRecording
        );
    }, [
        recordingState,
        showOnboardingModal,
        showImageTrackingOverlay,
        showTrackingOverlay,
        showInfoModal,
        fadeRecording,
    ]);

    const showLookup = useMemo(() => {
        return (
            isCameraDown &&
            recordingState.state === 'none' &&
            !showInfoModal &&
            experienceType === ExperienceType.MODEL
        );
    }, [isCameraDown, recordingState, showInfoModal, experienceType]);

    const showTrackingDot = useMemo(() => {
        return (
            !showOnboardingModal &&
            recordingState.state === 'none' &&
            !showInfoModal &&
            !showTrackingOverlay &&
            experienceType === ExperienceType.MODEL
        );
    }, [
        experienceType,
        recordingState.state,
        showInfoModal,
        showOnboardingModal,
        showTrackingOverlay,
    ]);

    const showMediaModal = useMemo(() => {
        return recordingState.state === 'ready';
    }, [recordingState.state]);

    const onVideoCleared = () => {
        if (recordingState.state === 'ready') {
            recordingState.clear();
        }
    };

    /**
     * Integrate renderer events with react state.
     */
    const [hasShownOverlayOnce, setHasShownOverlayOnce] = useState(false);

    useEffect(() => {
        if (!renderer) return;
        const handleTrackingStatus = (e: 'show' | 'hide') => {
            setTrackingStatus(e);
            if (uiStatus === 'show' && e === 'hide') {
                setUiStatus('hide');
            }
        };
        renderer.on('tracking-status', handleTrackingStatus);
        return () => {
            renderer.off('tracking-status', handleTrackingStatus);
        };
    }, [renderer, hasShownOverlayOnce, uiStatus]);

    useEffect(() => {
        if (!renderer) return;
        const handleLoadProgress = ({
            progress,
            total,
        }: {
            progress: number;
            total: number;
        }) => {
            setProgress({ progress: progress, total: total });
        };

        const handleCameraDown = (cameraDown: boolean) => {
            setIsCameraDown(cameraDown);
        };

        renderer.on('content-load-progress', handleLoadProgress);
        renderer.on('on-camera-down', handleCameraDown);
        return () => {
            renderer.off('content-load-progress', handleLoadProgress);
            renderer.off('on-camera-down', handleCameraDown);
        };
    }, [renderer]);

    const shouldRendererPause = useMemo(() => {
        return (
            showOnboardingModal ||
            recordingState.state === 'ready' ||
            (screenOrientation?.includes('landscape') && isMobile)
        );
    }, [showOnboardingModal, , recordingState, screenOrientation, isMobile]);

    useEffect(() => {
        if (!renderer) return;
        shouldRendererPause
            ? renderer.pauseTracking()
            : renderer.resumeTracking();
    }, [shouldRendererPause, renderer]);

    // @ts-expect-error: no event on window
    useEventListener('visibilitychange', () => {
        if (!renderer) return;
        if (experienceType === ExperienceType.MODEL) {
            if (document.visibilityState === 'visible') {
                renderer.resumeAudio();
            } else {
                renderer.pauseAudio();
            }
        }
    });

    /**
     * JSX
     */
    if (error) {
        return (
            <ErrorBoundary error={error.message} onResetError={resetErrors} />
        );
    }

    return (
        <>
            <FadeTransition show={showLookup} duration={500}>
                <h2 className="absolute bottom-10 w-full z-[100] font-mark-pro-black text-3xl text-shadow-base text-center">
                    <svg
                        className="relative w-full animate-swipe-up"
                        width="30"
                        height="34"
                        viewBox="0 0 30 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g filter="url(#filter0_d_407_13812)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.8758 0.00469971L29.6741 14.8029L25.8033 18.6737L17.6129 10.4833V31.5H12.1388V10.4833L4.19107 18.431L0.320312 14.5602L14.8758 0.00469971Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <filter
                                id="filter0_d_407_13812"
                                x="0.320312"
                                y="0.00469971"
                                width="29.3535"
                                height="33.4953"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                            >
                                <feFlood
                                    floodOpacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset dy="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_407_13812"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_407_13812"
                                    result="shape"
                                />
                            </filter>
                        </defs>
                    </svg>
                    LOOK UP
                </h2>
            </FadeTransition>
            {createPortal(
                <FadeTransition
                    show={screenOrientation?.includes('landscape') && isMobile}
                    duration={500}
                >
                    <LandscapeOverlay />
                </FadeTransition>,
                document.body,
            )}
            <FadeTransition show={showTrackingDot} duration={500}>
                <div className="absolute top-20 left-5 flex flex-row gap-x-4 justify-center items-center z-[999]">
                    <span
                        className={clsx(
                            'w-2 h-2 rounded-[50%] animate-fade-in z-[999]',
                            {
                                'bg-orange-500':
                                    (experienceType === ExperienceType.MODEL &&
                                        trackingStatus === 'show') ||
                                    imageTargetView ===
                                        ImageTargetView.SCANNING,
                                'bg-green-500':
                                    (experienceType === ExperienceType.MODEL &&
                                        trackingStatus === 'hide') ||
                                    imageTargetView === ImageTargetView.VIEWING,
                            },
                        )}
                    ></span>
                </div>
            </FadeTransition>

            {renderer && !isLoadingExperience ? (
                <div className="">
                    <FadeTransition
                        show={
                            !showOnboardingModal &&
                            !showInfoModal &&
                            recordingState.state === 'none'
                        }
                        duration={500}
                    >
                        <LogoMain className="absolute top-5 left-5" />
                    </FadeTransition>
                    <FadeTransition
                        show={
                            !showOnboardingModal &&
                            !showInfoModal &&
                            recordingState.state === 'none' &&
                            !showTrackingOverlay
                        }
                        duration={500}
                    >
                        <button
                            className="absolute top-5 right-5 z-[1] pointer-events-auto"
                            onClick={() => {
                                setShowInfoModal(true);
                            }}
                        >
                            <img
                                className="w-[48px] h-[48px]"
                                src={InfoButtonImg}
                            />
                        </button>
                    </FadeTransition>
                    {showImageTargetArtworkPopup &&
                        experienceType === ExperienceType.IMAGES && (
                            <ArtworkPopup currentArtwork={imageTarget} />
                        )}
                    {showModelArtworkPopup &&
                        experienceType === ExperienceType.MODEL && (
                            <ArtworkPopup
                                progress={progress}
                                currentArtwork={modelArtwork}
                            />
                        )}
                    <FadeTransition show={showTrackingOverlay} duration={500}>
                        <TrackingOverlay>
                            <GroundDetectionImg className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                            <h2 className="text-center font-mark-pro text-2xl tracking-[1px] text-shadow-base pb-12 font-secondary-sans">
                                Move your device to
                                <br />
                                detect the floor
                            </h2>
                        </TrackingOverlay>
                    </FadeTransition>
                    <FadeTransition
                        show={showImageTrackingOverlay}
                        duration={500}
                    >
                        <TrackingOverlay>
                            <ImageTrackingImg className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                            <h2 className="text-center font-mark-pro text-2xl tracking-[1px] text-shadow-base pb-12 font-secondary-sans">
                                Point your device at
                                <br />
                                the artwork
                            </h2>
                        </TrackingOverlay>
                    </FadeTransition>
                    <FadeTransition show={showOnboardingModal} duration={500}>
                        <OnboardingModals
                            mode={experienceType}
                            onClose={handleOnboardingClose}
                        />
                    </FadeTransition>
                    {createPortal(
                        <FadeTransition show={showInfoModal} duration={500}>
                            <InfoModal onClose={handleInfoClose} />
                        </FadeTransition>,
                        document.body,
                    )}
                    <FadeTransition show={showRecording} duration={500}>
                        <RecordingButton
                            recordingState={recordingState}
                            renderer={renderer}
                        />
                    </FadeTransition>
                    <FadeTransition show={showMediaModal}>
                        <MediaPreview
                            recordingState={recordingState}
                            onVideoCleared={onVideoCleared}
                        />
                    </FadeTransition>
                </div>
            ) : (
                <Splash
                    isLoadingExperience={isLoadingExperience}
                    device={device}
                    onPermissionsGranted={initExperience}
                />
            )}
        </>
    );
}
