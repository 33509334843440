import { useState } from 'preact/hooks';
import { Modal } from '../../components/Modal';
import { ModalOverlay } from '../../components/ModalOverlay';
import { VideoRecorderResult } from '../recording-button';
import clsx from 'clsx';

export type MediaPreviewProps = {
    recordingState: VideoRecorderResult;
    onVideoCleared: () => void;
};

export function MediaPreview({
    recordingState,
    onVideoCleared,
}: MediaPreviewProps) {
    const [videoLoaded, setVideoLoaded] = useState(false);
    const onVideoDataLoaded = () => {
        setVideoLoaded(true);
    };

    if (recordingState.state !== 'ready') return null;
    return (
        <ModalOverlay className="z-[200]">
            <Modal
                maxHeight="max-h-full"
                className={clsx(
                    'flex flex-col items-center justify-center p-4 h-full centered',
                )}
            >
                <div className="relative w-auto border-white z-[100] border-[3px] rounded-[22px] w-full max-w-[70vw]">
                    <>
                        <button
                            className="absolute top-3 right-3 z-[100]"
                            onClick={onVideoCleared}
                        >
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="1"
                                    y="1"
                                    width="32"
                                    height="31.6079"
                                    stroke="white"
                                />
                                <path
                                    d="M17.1992 18.2596L13.1909 22.2188L11.918 20.9548L15.9195 17.0022L11.918 13.043L13.1909 11.7857L17.1992 15.7382L21.2008 11.7857L22.4737 13.043L18.4721 17.0022L22.4737 20.9548L21.2008 22.2121L17.1992 18.2596Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                        <video
                            src={recordingState.videoUrl}
                            className={clsx(
                                'object-cover rounded-[20px] min-h-[50vh]',
                                {
                                    'animate-skeleton-load bg-gray-300 ':
                                        !videoLoaded,
                                },
                            )}
                            playsInline
                            autoPlay
                            muted
                            loop
                            onLoadedData={onVideoDataLoaded}
                        />
                    </>
                </div>
                <button
                    className="mt-16 text-roboto max-w-[55vw] rounded-sm button-default bg-opacity-0 active:opacity-70"
                    onClick={() =>
                        recordingState.download('music_centre_ar_video.mp4')
                    }
                >
                    SHARE
                </button>
            </Modal>
        </ModalOverlay>
    );
}
