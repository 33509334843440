type ImageTrackingImgProps = {
    className?: string;
};
export function ImageTrackingImg({ className }: ImageTrackingImgProps) {
    return (
        <svg
            width="222"
            height="302"
            viewBox="0 0 222 302"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g filter="url(#filter0_d_76_51765)">
                <path
                    d="M123.193 253.323H94.188M86.7563 220.61V160.064C86.7563 156.701 89.357 153.948 92.5355 153.948H127.774C130.953 153.948 133.553 156.701 133.553 160.064V199.966M116.096 280.059C116.096 280.059 114.285 276.818 108.463 272.711C103.625 269.297 100.617 263.945 95.6065 253.323M86.7563 205.623C86.7563 205.623 83.9986 206.32 81.5737 207.439C77.1294 209.491 76.6813 212.144 75.5982 215.101C73.4149 221.06 75.2517 223.753 75.2517 223.753L83.4677 233.686C86.0957 235.771 92.7293 229.147 89.8136 224.792C88.6674 223.079 85.325 218.73 85.325 218.73M77.5792 240.893C76.1231 242.875 74.4144 247.017 74.9777 249.234C75.6912 252.041 79.0737 255.307 81.1435 256.297C83.0862 257.226 89.4782 258.21 89.4782 258.21C93.739 259.462 95.7678 250.789 93.0348 248.655C91.6009 247.535 87.1267 246.226 87.1267 246.226M86.7563 189.354C86.7563 189.354 83.6173 188.726 80.7409 187.79C74.9361 185.9 72.6691 194.738 76.9956 197.339C78.5448 198.271 86.7563 202.201 86.7563 202.201M75.1962 223.595C71.9127 229.097 73.0624 235.046 73.8835 236.494C75.104 238.647 76.6199 239.969 76.6199 239.969C76.6199 239.969 81.3501 244.57 83.4976 246.277C86.8408 248.933 92.6832 241.683 90.0842 238.244C88.8457 236.604 86.455 233.56 86.455 233.56M151.721 272.265C150.377 270.066 149.325 267.685 149.085 261.591C148.826 255.03 152.755 244.828 151.91 237.276C151.251 231.382 144.926 214.229 144.926 214.229C141.59 209.529 140.643 204.339 140.195 198.639C140.195 198.639 135.257 196.646 132.478 201.457C129.468 206.669 129.671 214.327 133.284 219.895C135.195 222.84 136.605 233.29 134.041 235.744C130.1 239.516 123.95 245.785 122.757 256.143"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <path
                d="M87 177H23V1H199V177H133.5"
                stroke="white"
                strokeWidth="2"
                strokeDasharray="4 4"
            />
            <path
                d="M87 167H33V11H189V167H133.5"
                stroke="white"
                strokeWidth="2"
            />
            <defs>
                <filter
                    id="filter0_d_76_51765"
                    x="-20"
                    y="26"
                    width="262"
                    height="275.059"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_76_51765"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_76_51765"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
