import { atom, createStore } from 'jotai';
import { ImageTargetSwitcher } from '../image-target-switcher';
import { IArtwork } from '../../types';

export const imageTargetStore = createStore();

export const imageTargetAtom = atom<IArtwork | undefined>(undefined);
export const artworkLoadingAtom = atom<boolean>(false);

const video = document.getElementById('ej-video') as HTMLVideoElement;
export const imageTargetVideo = atom<HTMLVideoElement>(video);

const imageTargetSwitcher = new ImageTargetSwitcher();

const unsub = imageTargetStore.sub(imageTargetAtom, () => {
    // console.log(
    //     'imageTargetAtom value is changed to',
    //     imageTargetStore.get(imageTargetAtom),
    // );
    const image = imageTargetStore.get(imageTargetAtom);
    if (image) {
        imageTargetSwitcher.setTargetId = image.targetName.slice(-2);
        imageTargetSwitcher.handleMarkerSwitch();
    }
});

export enum ImageTargetView {
    ONBOARDING,
    SCANNING,
    VIEWING,
}
export const imageTargetViewAtom = atom<ImageTargetView>(
    ImageTargetView.ONBOARDING,
);
