import * as THREE from 'three';
import {
    I8thWallImageTargetEventModel,
    I8thWallOnStartModel,
    IPipelineModule,
} from '.';
import mitt, { Emitter } from 'mitt';

window.THREE = THREE;

export type MusicCentreImageTargetEvents = {
    'on-show-target': {
        detail: I8thWallImageTargetEventModel['detail'];
    };
    'on-update-target': {
        detail: I8thWallImageTargetEventModel['detail'];
    };
    'on-update-scene': void;
    'on-hide-target': {
        detail: I8thWallImageTargetEventModel['detail'];
    };
    'on-targets-scanned': {
        detail: I8thWallImageTargetEventModel['detail'];
    };
    'on-render': void;
    'content-load-progress': number;
    'content-loaded': void;
    setup: {
        scene: THREE.Scene;
        camera: THREE.Camera;
    };
    'resume-tracking': void;
    'pause-tracking': void;
};

const emitter = mitt<MusicCentreImageTargetEvents>();

export type MusicCentreImageTargetPipelineModuleResult = IPipelineModule & {
    emitter: Emitter<MusicCentreImageTargetEvents>;
};

export const musicCentreImageTargetPipelineModule = (): any => {
    const targetFound = ({ detail }: I8thWallImageTargetEventModel) => {
        emitter.emit('on-show-target', { detail });
    };

    const updateTarget = ({ detail }: I8thWallImageTargetEventModel) => {
        emitter.emit('on-update-target', { detail });
    };

    const hideTarget = ({ detail }: I8thWallImageTargetEventModel) => {
        emitter.emit('on-hide-target', { detail });
    };

    return {
        name: 'music-centre-image-target',
        emitter,
        onStart: ({ canvas }: Partial<I8thWallOnStartModel>) => {
            // @ts-expect-error
            const { scene, camera, renderer } = XR8.Threejs.xrScene();
            camera.position.set(0, 3, 0);
            scene.add(new THREE.AmbientLight(0xffffff, 1));
            const directionalLight = new THREE.DirectionalLight(0xffffff, 2.5);
            scene.add(directionalLight);
            directionalLight.position.x = -1;
            directionalLight.position.y = 0;

            emitter.emit('setup', { scene, camera });

            // prevent scroll/pinch gestures on canvas
            canvas?.addEventListener('touchmove', (event) => {
                event.preventDefault();
            });

            // Sync the xr controller's 6DoF position and camera paremeters with our scene.
            // @ts-expect-error
            XR8.XrController.updateCameraProjectionMatrix({
                origin: camera.position,
                facing: camera.quaternion,
            });
        },
        onUpdate: () => {
            emitter.emit('on-update-scene');
        },
        onRender: () => {
            emitter.emit('on-render');
        },
        onException: (error: Error) => {
            console.log('Image target pipeline module error: ', error);
        },

        listeners: [
            { event: 'reality.imagefound', process: targetFound },
            { event: 'reality.imageupdated', process: updateTarget },
            { event: 'reality.imagelost', process: hideTarget },
        ],
    };
};
