import { Vector2 } from 'three';

export const scaleToFit = (v: Vector2, toContain: Vector2) => {
    const diff = v.clone().sub(toContain);
    let scalar = 0;
    if (diff.x > diff.y) {
        scalar = toContain.x / v.x;
    } else scalar = toContain.y / v.y;

    const ret = v.clone().multiplyScalar(scalar);
    return ret;
};

export const scaleToCover = (v: Vector2, toCover: Vector2) => {
    const diff = v.clone().sub(toCover);
    let scalar = 0;
    if (diff.x < diff.y) {
        scalar = toCover.x / v.x;
    } else scalar = toCover.y / v.y;

    const ret = v.clone().multiplyScalar(scalar);

    return ret;
};

type ArtworkDims = {
    width: number;
    height: number;
};

export const getArtworkTargetDimensions = async (artwork: any) => {
    return new Promise<ArtworkDims>((res) => {
        const imgTargetFile = artwork.files.find(
            (f) => f.type === 'artwork512',
        );
        const { width, height } = imgTargetFile;
        if (width && height) {
            res({ width, height });
        } else {
            const img = new Image(imgTargetFile.path);
            img.addEventListener('load', () => {
                const { naturalWidth, naturalHeight } = img;
                res({ width: naturalWidth, height: naturalHeight });
            });
            img.src = imgTargetFile.path;
        }
    });
};
