import { atom, createStore } from 'jotai';
import { IArtwork } from '../renderer/types';
import { artworkData } from '../renderer/artworks';

export const experienceStore = createStore();

export enum ExperienceType {
    MODEL,
    IMAGES,
}
/**
 * ?t=images || ?t=model
 */
export const experienceTypeAtom = atom<ExperienceType>(
    window.location.search === '?t=images'
        ? ExperienceType.IMAGES
        : ExperienceType.MODEL,
);

export const currentArtworkAtom = atom<IArtwork | undefined>(undefined);

export const setCurrentArtworkAtom = atom(
    (get) => get(currentArtworkAtom),
    // null,
    (get, set, update) => {
        set(currentArtworkAtom, (artwork) => {
            const newArtwork = artworkData.find(
                (a) => a.targetName === artwork?.targetName,
            );
            return newArtwork;
        });
    },
);
