import 'preact/debug';
import { render } from 'preact';
import { App } from './app.tsx';
import './index.css';
import { Provider } from 'jotai';
import { imageTargetStore } from './renderer/8thwall/atoms';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-FK9H4CGKM8');

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: 'https://135a0784cc2986467a9cbfbac04f07a5@o1388406.ingest.sentry.io/4506792928477184',
        integrations: [],
    });
}

render(
    <Provider store={imageTargetStore}>
        <App />
    </Provider>,
    document.getElementById('app')!,
);
