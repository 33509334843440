type LogoMainProps = {
    className?: string;
};
export function LogoMain({ className }: LogoMainProps) {
    return (
        <svg
            width="170"
            height="51"
            viewBox="0 0 170 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g filter="url(#filter0_d_55_35298)">
                <path
                    d="M25.9012 0.856194V22H20.1621V8.77002L16.764 20.0366H10.6474L7.2493 8.77002V22H1.51027V0.856194H9.4694L13.7133 13.5727L17.942 0.856194H25.9012ZM47.4898 0.856194V13.3763C47.4898 19.1305 44.1974 22.3927 38.1261 22.3927C32.0548 22.3927 28.7624 19.1305 28.7624 13.3763V0.856194H34.5166V13.2102C34.5166 15.6418 35.906 16.8953 38.1261 16.8953C40.3462 16.8953 41.7357 15.6418 41.7357 13.2102V0.856194H47.4898ZM68.134 2.69873L65.6572 7.39567C64.2677 6.61033 62.1685 5.62865 59.5557 5.62865C58.0152 5.62865 56.8372 6.05153 56.8372 7.003C56.8372 8.19612 58.9969 8.31694 61.1566 8.73981L61.8362 8.87574C66.2009 9.72149 68.421 11.8208 68.421 15.0679C68.421 20.4142 63.9053 22.3927 59.4047 22.3927C55.6139 22.3927 52.1705 21.4865 49.754 19.7648L52.5631 15.083C54.2395 16.0948 56.2633 17.2577 59.4802 17.2577C61.0206 17.2577 62.1533 16.8349 62.1533 15.7928C62.1533 14.6903 60.8394 14.4939 58.2115 14.0409L57.4111 13.9049C52.548 13.0743 50.5243 10.975 50.63 7.22954C50.7357 3.18201 54.1338 0.478627 59.2083 0.478627C61.9721 0.478627 64.4641 0.765579 68.134 2.69873ZM76.5684 0.856194V22H70.8294V0.856194H76.5684ZM89.9959 0.463523C94.1341 0.463523 97.804 2.44198 99.6466 5.82499L94.5569 8.63409C93.53 6.88218 91.9593 5.96091 89.9053 5.96091C86.9754 5.96091 84.8006 8.19612 84.8006 11.4281C84.8006 15.0376 87.3076 16.8953 89.9204 16.8953C91.9291 16.8953 93.5451 15.9891 94.6929 14.2372L99.4049 17.5296C97.4114 20.5199 94.1794 22.3927 89.9959 22.3927C83.4564 22.3927 78.835 17.8619 78.835 11.4281C78.835 4.99434 83.4564 0.463523 89.9959 0.463523Z"
                    fill="white"
                />
                <path
                    d="M105.704 25.0867L99.0038 48.5371H91.4997L87.5801 34.8186L83.6605 48.5371H76.1564L69.4563 25.0867H76.0056L80.009 40.8152L84.23 25.0867H91.0139L95.235 40.832L99.2383 25.0867H105.704ZM119.943 25.0867L128.82 48.5371H122.07L120.512 44.0648H111.752L110.211 48.5371H103.561L112.439 25.0867H119.943ZM116.14 31.4518L113.728 38.3864H118.536L116.14 31.4518ZM148.227 42.6745V48.5371H130.572V25.0867H136.937V42.6745H148.227ZM167.889 42.6745V48.5371H150.234V25.0867H156.599V42.6745H167.889Z"
                    fill="white"
                />
                <path
                    d="M50.264 25.9212V27.8252H48.0989V33.5371H46.0317V27.8252H43.8666V25.9212H50.264ZM52.9882 25.9212V28.7391H55.8061V25.9212H57.8733V33.5371H55.8061V30.6376H52.9882V33.5371H50.9211V25.9212H52.9882ZM64.9154 31.6984V33.5371H58.9641V25.9212H64.9154V27.7599H61.0313V28.7663H64.6434V30.605H61.0313V31.6984H64.9154Z"
                    fill="white"
                />
                <path
                    d="M5.4707 25.8339C7.80976 25.8339 9.46817 27.4977 9.46817 29.7557C9.46817 32.0137 7.80976 33.6776 5.4707 33.6776C3.13164 33.6776 1.47322 32.0137 1.47322 29.7557C1.47322 27.4977 3.13164 25.8339 5.4707 25.8339ZM5.4707 27.8002C4.36869 27.8002 3.60701 28.5997 3.60701 29.7557C3.60701 30.9117 4.36869 31.7112 5.4707 31.7112C6.5727 31.7112 7.33438 30.9117 7.33438 29.7557C7.33438 28.5997 6.5727 27.8002 5.4707 27.8002ZM15.8645 28.9616V30.7875H12.3316V33.5371H10.2789V25.9743H16.1346V27.8002H12.3316V28.9616H15.8645ZM22.5854 28.9616V30.7875H19.0525V33.5371H16.9997V25.9743H22.8555V27.8002H19.0525V28.9616H22.5854Z"
                    fill="white"
                />
                <line
                    x1="26.7695"
                    y1="29.4035"
                    x2="40.325"
                    y2="29.4035"
                    stroke="white"
                    strokeWidth="0.503573"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_55_35298"
                    x="0"
                    y="0"
                    width="170.322"
                    height="51"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="2" dy="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_55_35298"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_55_35298"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
