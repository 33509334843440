type GroundDetectionImgProps = {
    className?: string;
};
export function GroundDetectionImg({ className }: GroundDetectionImgProps) {
    return (
        <svg
            className={className}
            width="262"
            height="263"
            viewBox="0 0 262 263"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_215_3230)">
                <path
                    d="M107 139.913H24.3088C22.2198 139.913 20.7703 137.831 21.4953 135.872L63.2752 22.9589C63.7108 21.7816 64.8334 21 66.0887 21H196.403C197.662 21 198.788 21.7866 199.22 22.9693L240.526 135.882C241.242 137.84 239.792 139.913 237.708 139.913H153.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeDasharray="4 4"
                />
                <path
                    d="M149.934 64.8255L172.488 49.331C172.822 49.1021 173.216 48.9795 173.621 48.9795H181.409C182.34 48.9795 183.148 49.6221 183.358 50.5295L184.211 54.2224C184.387 54.9853 184.102 55.7809 183.481 56.2581L172.451 64.7362C171.866 65.1863 171.576 65.9224 171.697 66.6509L172.853 73.5768C172.946 74.1392 173.276 74.6348 173.758 74.9391L191.252 85.9798C191.728 86.2806 192.056 86.7684 192.154 87.3233L193.085 92.598C193.302 93.8228 192.36 94.9458 191.116 94.9458H181.617C181.215 94.9458 180.822 94.8247 180.49 94.5981L150.308 74.0127C149.798 73.665 149.477 73.1013 149.439 72.4853L149.071 66.5988C149.027 65.897 149.355 65.2237 149.934 64.8255Z"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M111.566 64.8255L89.0117 49.331C88.6784 49.1021 88.2835 48.9795 87.8792 48.9795H80.0908C79.1596 48.9795 78.3516 49.6221 78.1421 50.5295L77.2892 54.2224C77.113 54.9853 77.3983 55.7809 78.0191 56.2581L89.0487 64.7362C89.6343 65.1863 89.9241 65.9224 89.8026 66.6509L88.6474 73.5768C88.5536 74.1392 88.2243 74.6348 87.7421 74.9391L70.2482 85.9798C69.7717 86.2806 69.4441 86.7684 69.3461 87.3233L68.4146 92.598C68.1983 93.8228 69.1404 94.9458 70.3841 94.9458H79.8829C80.2849 94.9458 80.6777 94.8247 81.0098 94.5981L111.192 74.0127C111.702 73.665 112.023 73.1013 112.061 72.4853L112.429 66.5988C112.473 65.897 112.145 65.2237 111.566 64.8255Z"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M131 82.455H137.369C137.78 82.455 138.18 82.5815 138.517 82.8173L170.805 105.451C171.249 105.762 171.548 106.24 171.632 106.776L172.635 113.119C172.826 114.333 171.888 115.431 170.659 115.431H160.162C159.732 115.431 159.314 115.292 158.969 115.036L140.531 101.336C140.186 101.08 139.768 100.941 139.338 100.941H131"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M131 82.455H124.631C124.22 82.455 123.82 82.5815 123.483 82.8173L91.195 105.451C90.7508 105.762 90.4522 106.24 90.3675 106.776L89.3653 113.119C89.1735 114.333 90.1118 115.431 91.3408 115.431H101.838C102.268 115.431 102.686 115.292 103.031 115.036L121.469 101.336C121.814 101.08 122.232 100.941 122.662 100.941H131"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M131 57.9729L135.373 57.9729C135.781 57.9729 136.18 57.848 136.515 57.6151L158.053 42.6431C158.639 42.2355 158.963 41.5463 158.904 40.8347L158.661 37.9218C158.571 36.8449 157.642 36.034 156.563 36.0908L149.558 36.4592C149.193 36.4784 148.84 36.5972 148.538 36.8026L138.509 43.6218C138.177 43.8473 137.786 43.9679 137.384 43.9679L131 43.9679"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M131 57.9729L126.627 57.9729C126.219 57.9729 125.82 57.848 125.485 57.6151L103.947 42.6431C103.361 42.2355 103.037 41.5463 103.096 40.8347L103.339 37.9218C103.429 36.8449 104.358 36.034 105.437 36.0908L112.442 36.4592C112.807 36.4784 113.16 36.5972 113.462 36.8026L123.491 43.6218C123.823 43.8473 124.214 43.9679 124.616 43.9679L131 43.9679"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M172 171.14C171.586 171.14 171.25 171.475 171.25 171.89C171.25 172.304 171.586 172.64 172 172.64L172 171.14ZM197.53 172.42C197.823 172.127 197.823 171.652 197.53 171.359L192.757 166.586C192.464 166.293 191.99 166.293 191.697 166.586C191.404 166.879 191.404 167.354 191.697 167.647L195.939 171.89L191.697 176.132C191.404 176.425 191.404 176.9 191.697 177.193C191.99 177.486 192.464 177.486 192.757 177.193L197.53 172.42ZM172 172.64L197 172.64L197 171.14L172 171.14L172 172.64Z"
                    fill="white"
                />
                <path
                    d="M83 172.64C83.4142 172.64 83.75 172.304 83.75 171.89C83.75 171.475 83.4142 171.14 83 171.14V172.64ZM57.4697 171.359C57.1768 171.652 57.1768 172.127 57.4697 172.42L62.2426 177.193C62.5355 177.486 63.0104 177.486 63.3033 177.193C63.5962 176.9 63.5962 176.425 63.3033 176.132L59.0607 171.89L63.3033 167.647C63.5962 167.354 63.5962 166.879 63.3033 166.586C63.0104 166.293 62.5355 166.293 62.2426 166.586L57.4697 171.359ZM83 171.14H58V172.64H83V171.14Z"
                    fill="white"
                />
                <path
                    d="M143.193 216.756H114.188M106.756 185.868V128.7C106.756 125.524 109.357 122.925 112.536 122.925H147.774C150.953 122.925 153.553 125.524 153.553 128.7V166.375M136.096 242C136.096 242 134.285 238.94 128.463 235.062C123.625 231.839 120.617 226.785 115.607 216.756M106.756 171.717C106.756 171.717 103.999 172.375 101.574 173.432C97.1294 175.369 96.6813 177.874 95.5982 180.666C93.4149 186.293 95.2517 188.835 95.2517 188.835L103.468 198.214C106.096 200.183 112.729 193.929 109.814 189.816C108.667 188.199 105.325 184.092 105.325 184.092M97.5792 205.019C96.1231 206.89 94.4144 210.802 94.9777 212.895C95.6912 215.545 99.0737 218.629 101.144 219.564C103.086 220.441 109.478 221.37 109.478 221.37C113.739 222.552 115.768 214.363 113.035 212.348C111.601 211.291 107.127 210.055 107.127 210.055M106.756 156.355C106.756 156.355 103.617 155.763 100.741 154.879C94.9361 153.094 92.6691 161.439 96.9956 163.895C98.5448 164.775 106.756 168.486 106.756 168.486M95.1962 188.686C91.9127 193.881 93.0624 199.498 93.8835 200.866C95.104 202.899 96.6199 204.147 96.6199 204.147C96.6199 204.147 101.35 208.491 103.498 210.102C106.841 212.611 112.683 205.765 110.084 202.518C108.846 200.97 106.455 198.095 106.455 198.095M171.721 234.641C170.377 232.564 169.325 230.316 169.085 224.562C168.826 218.367 172.755 208.734 171.91 201.604C171.251 196.039 164.926 179.842 164.926 179.842C161.59 175.405 160.643 170.505 160.195 165.123C160.195 165.123 155.257 163.241 152.478 167.783C149.468 172.705 149.671 179.935 153.284 185.193C155.195 187.973 156.605 197.84 154.041 200.158C150.1 203.719 143.95 209.638 142.757 219.418"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_215_3230"
                    x="0"
                    y="0.25"
                    width="262"
                    height="262.5"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_215_3230"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_215_3230"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
