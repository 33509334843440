import clsx from 'clsx';
import { IArtwork } from '../../renderer/types';
import { useMemo, useState } from 'preact/hooks';

type ArtworkPopupProps = {
    currentArtwork: IArtwork | undefined;
    progress?: { progress: number; total: number };
};
export function ArtworkPopup({ currentArtwork, progress }: ArtworkPopupProps) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const formattedLink = useMemo(() => {
        if (!currentArtwork) return;
        return currentArtwork.instagram?.includes('www')
            ? currentArtwork.instagram.replace(/^https:\/\/www\./, '')
            : currentArtwork.instagram?.replace(/^https:\/\//, '');
    }, [currentArtwork]);

    const handlePopupClick = (e: Event) => {
        e.stopPropagation();
        setIsPopupOpen(!isPopupOpen);
    };
    if (!currentArtwork) return null;

    return (
        <div className="absolute bottom-0 right-0 flex p-4 px-5 z-[100] pb-10 animate-slide-left w-full items-end justify-end">
            <div
                className={clsx(
                    'transition-all duration-500 pointer-events-auto relative  ',
                    {
                        'w-full  h-[185px]': isPopupOpen,
                        'w-[72px] h-[72px]': !isPopupOpen,
                    },
                )}
                onClick={handlePopupClick}
            >
                <div
                    className={clsx(
                        ' flex flex-row-reverse items-start justify-between w-full h-full ',
                        {
                            'bg-transparent':
                                progress &&
                                ~~Math.ceil(
                                    (progress.progress / progress.total) * 100,
                                ) < 100,
                            'bg-[#B6327D]':
                                !progress ||
                                (progress &&
                                    ~~Math.ceil(
                                        (progress.progress / progress.total) *
                                            100,
                                    ) === 100),
                        },
                    )}
                >
                    {progress && (
                        <div
                            className={clsx(
                                'w-[72px] h-[72px] absolute z-10 top-0 left-0  border-2 border-white transition-opacity duration-500',
                                {
                                    'opacity-1':
                                        ~~Math.ceil(
                                            (progress.progress /
                                                progress.total) *
                                                100,
                                        ) < 100,
                                    'opacity-0':
                                        !progress ||
                                        ~~Math.ceil(
                                            (progress.progress /
                                                progress.total) *
                                                100,
                                        ) === 100,
                                },
                            )}
                        >
                            <div
                                style={{
                                    width: progress
                                        ? `${~~Math.ceil(
                                              (progress.progress /
                                                  progress.total) *
                                                  100,
                                          )}%`
                                        : '100%',
                                }}
                                className="bg-[#B6327D] w-[72px] h-[68px] "
                            >
                                <p className="font-mark-pro-black text-[20px] absolute bottom-0 right-1 z-20">
                                    {
                                        ~~Math.ceil(
                                            (progress.progress /
                                                progress.total) *
                                                100,
                                        )
                                    }
                                    %
                                </p>
                            </div>
                        </div>
                    )}

                    <>
                        <div
                            className={clsx(
                                'transition-all duration-500 overflow-hidden',
                                {
                                    'max-h-full opacity-1 w-full p-4 pl-0':
                                        isPopupOpen,
                                    'max-h-0 opacity-0 w-0 p-0 ': !isPopupOpen,
                                },
                            )}
                        >
                            {currentArtwork.creativeDirection ? (
                                <>
                                    <h2 className="font-mark-pro text-[0.5rem] pt-2 pb-[3px] leading-none">
                                        ARTIST & ANIMATOR
                                    </h2>
                                    <p className="font-mark-pro text-xs font-extrabold leading-none">
                                        {currentArtwork.artist}
                                    </p>
                                    <h2 className="font-mark-pro text-[0.5rem] pt-4  pb-[3px] leading-none">
                                        CREATIVE DIRECTION
                                    </h2>
                                    <p className="font-mark-pro text-xs font-extrabold leading-none">
                                        {currentArtwork.creativeDirection}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <h2 className="font-mark-pro text-[0.5rem] pt-2 pb-[3px] leading-none">
                                        ARTIST
                                    </h2>
                                    <p className="font-mark-pro text-xs font-extrabold leading-none">
                                        {currentArtwork.artist}
                                    </p>
                                    <h2 className="font-mark-pro text-[0.5rem] pt-4  pb-[3px] leading-none">
                                        ANIMATOR
                                    </h2>
                                    <p className="font-mark-pro text-xs font-extrabold leading-none">
                                        {currentArtwork.animator}
                                    </p>
                                </>
                            )}
                            <h2 className="font-mark-pro text-[0.5rem] pt-4  pb-[3px] leading-none">
                                SOUND DESIGNER
                            </h2>
                            <p className="font-mark-pro text-xs font-extrabold leading-none">
                                {currentArtwork.soundDesigner}
                            </p>
                            <a
                                className="block w-fit "
                                href={currentArtwork.instagram}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p className="font-mark-pro text-xs font-bold pt-[20px] underline underline-offset-2 leading-none text-[#95D1F2] ">
                                    instagram
                                </p>
                            </a>
                        </div>
                        <img
                            className={clsx(
                                'max-h-[172px] max-w-full w-auto h-full transition-opacity duration-500',
                                {
                                    'p-4': isPopupOpen,
                                    'p-0 border-2 border-white relative':
                                        !isPopupOpen,
                                    'opacity-0':
                                        progress &&
                                        ~~Math.ceil(
                                            (progress.progress /
                                                progress.total) *
                                                100,
                                        ) < 100,
                                    'opacity-1':
                                        !progress ||
                                        (progress &&
                                            ~~Math.ceil(
                                                (progress.progress /
                                                    progress.total) *
                                                    100,
                                            ) === 100),
                                },
                            )}
                            src={currentArtwork.imageSquare}
                            alt="artwork image"
                        />
                        <div
                            className={clsx('', {
                                'bg-black bg-opacity-[0.2] max-h-[68px] max-w-[68px] w-full h-full absolute inset-[2px]':
                                    !isPopupOpen,
                            })}
                        ></div>
                        <button
                            className={clsx(
                                'absolute z-10 transition-all duration-500',
                                {
                                    ' left-[10px] top-[10px]': !isPopupOpen,
                                    ' left-[calc(100%-35px)] top-[calc(100%-35px)] rotate-180':
                                        isPopupOpen,
                                    'opacity-0':
                                        progress &&
                                        ~~Math.ceil(
                                            (progress.progress /
                                                progress.total) *
                                                100,
                                        ) < 100,
                                    'opacity-1':
                                        !progress ||
                                        (progress &&
                                            ~~Math.ceil(
                                                (progress.progress /
                                                    progress.total) *
                                                    100,
                                            ) === 100),
                                },
                            )}
                            onClick={handlePopupClick}
                        >
                            <svg
                                width="23"
                                height="23"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.6152 2.99997L2.99985 2.99997L2.99985 19.3019"
                                    stroke="white"
                                    strokeWidth="5"
                                />
                                <path
                                    d="M3.34615 3.6792L21 21"
                                    stroke="white"
                                    strokeWidth="5"
                                />
                            </svg>
                        </button>
                    </>
                </div>
            </div>
        </div>
    );
}
