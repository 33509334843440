import { Slider, SliderController } from '../../components/Slider';
import { Slide } from '../../components/Slide';
import { Modal } from '../../components/Modal';
import { ModalOverlay } from '../../components/ModalOverlay';
import 'keen-slider/keen-slider.min.css';
import { SliderControlButton } from '../../components/SliderControlButton';
import Lockup from './assets/onboarding-lockup.svg';
import HorizontalLogo from '../../assets/horizontal-logo.svg';
import VerticalLogo from '../../assets/vertical-logo.svg';
import ImageTargetIcon from './assets/image-target-icon.svg';
import ARIcon from './assets/ar-icon.png';
import PlayHeader from './assets/play-header.svg';
import ScanHeader from './assets/scan-header.svg';
import { ExperienceType } from '../../atoms';

type OnboardingModalsProps = {
    mode: ExperienceType;
    onClose: () => void;
};

export const OnboardingModals = ({ mode, onClose }: OnboardingModalsProps) => {
    const sliderController: SliderController = {
        post: () => {},
    };
    return (
        <ModalOverlay>
            <Modal
                maxWidth="max-w-[390px]"
                maxHeight="max-h-[540px]"
                className=" bg-cover text-white centered w-[92%] rounded-none"
            >
                <Slider controller={sliderController}>
                    <Slide className="flex flex-col h-full justify-between bg-[#B6327D] bg-opacity-90">
                        <img
                            className="self-end h-[46px]"
                            src={HorizontalLogo}
                        />
                        <div className="flex flex-col flex-grow h-full items-center justify-center">
                            <img src={Lockup} className="w-full" />
                        </div>
                        <SliderControlButton
                            sliderController={sliderController}
                            message="next"
                            text="NEXT"
                        />
                    </Slide>
                    <Slide className="flex flex-col h-full justify-between bg-[#B6327D]">
                        <img
                            className="absolute top-10 right-[30px] w-[50px]"
                            src={VerticalLogo}
                        />
                        <div className="flex flex-col flex-grow h-full justify-start gap-[30px]">
                            {mode === ExperienceType.IMAGES && (
                                <>
                                    <img
                                        src={ImageTargetIcon}
                                        className="max-w-[184px] w-full self-start"
                                    />
                                    <img
                                        src={ScanHeader}
                                        className="w-[76%] self-start"
                                    />
                                    <p className="text-lg font-mark-pro-xtralite leading-[110%]">
                                        To unlock the immersive musical and
                                        visual adventure behind each artwork,
                                        point your camera at any of the
                                        captivating pieces nearby and watch them
                                        come to life with augmented reality.
                                    </p>
                                </>
                            )}
                            {mode === ExperienceType.MODEL && (
                                <>
                                    <img
                                        src={ARIcon}
                                        className="max-w-[240px] w-full self-start"
                                    />
                                    <img
                                        src={PlayHeader}
                                        className="w-[76%] self-start"
                                    />
                                    <p className="text-lg font-mark-pro-xtralite leading-[125%]">
                                        Get ready for an immersive AR
                                        experience. Please follow the next two
                                        easy steps to launch and interact with
                                        the augmented reality sculpture.
                                    </p>
                                </>
                            )}
                        </div>
                        <SliderControlButton
                            sliderController={sliderController}
                            message="undefined"
                            text="BEGIN"
                            onClick={onClose}
                        />
                    </Slide>
                </Slider>
            </Modal>
        </ModalOverlay>
    );
};
