import clsx from 'clsx';
import { useMemo, useState } from 'preact/hooks';
import { useTimeout } from 'usehooks-ts';
import Logo from '../../assets/horizontal-logo.svg';
import Spinner from '../../components/Spinner';
import { TDevice } from '../../hooks/useUserDevice';
import { Logo8thWall } from './Logo8thWall';
import { QrCodeGenerator } from './QrCodeGenerator';
import MobileSplash from './assets/mobile-splash.svg';
import SplashLockup from './assets/splash-lockup.svg';

type SplashProps = {
    onPermissionsGranted: () => void;
    isLoadingExperience: boolean;
    device: TDevice | undefined;
};

export function Splash({
    onPermissionsGranted,
    isLoadingExperience,
    device,
}: SplashProps) {
    const [permissionDenied, setPermissionDenied] = useState<
        'camera-denied' | 'motion-denied' | undefined
    >(undefined);

    const requestCamera = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then(() => {
                onPermissionsGranted();
            })
            .catch((err) => {
                setPermissionDenied('camera-denied');
                console.log('Error: camera rejected.' + err);
            });
    };

    const requestMotion = () => {
        // video and audio need to unmute on a user click
        const video = document.getElementById('ej-video') as HTMLVideoElement;
        const audio = document.getElementById('ejx-audio') as HTMLAudioElement;
        if (video) video.muted = false;
        if (audio) {
            audio.pause();
            audio.muted = false;
        }
        // imageTargetVideo.init.muted = false
        if (
            typeof DeviceMotionEvent !== 'undefined' &&
            typeof (DeviceMotionEvent as any).requestPermission === 'function'
        ) {
            // motion avaiable
            (DeviceMotionEvent as any)
                .requestPermission()
                .then((response: string) => {
                    if (response === 'granted') {
                        requestCamera();
                    } else {
                        console.log(response);
                        setPermissionDenied('motion-denied');
                    }
                })
                .catch((err: Error) => {
                    setPermissionDenied('motion-denied');
                    console.log('Error: motion rejected.' + err);
                });
        } else {
            requestCamera();
        }
    };

    const [pageType, setPageType] = useState<'loading' | 'default'>('loading');
    const uiType = useMemo(() => {
        if (pageType === 'loading') {
            return 'loading';
        } else return 'default';
    }, [pageType]);

    useTimeout(() => {
        setPageType('default');
    }, 2000);

    const deviceType = useMemo(() => {
        if (device === undefined) {
            return 'loading';
        } else if (device === 'iOS' || device === 'Android') {
            return 'mobile';
        } else return 'desktop';
    }, [device]);

    return (
        <div
            className={clsx(
                'absolute touch-none inset-0 z-[1000] bg-cover bg-center h-full w-full flex text-white pointer-events-auto items-center overflow-hidden px-4 gap-4  md:px-8',
                {
                    'bg-[#B6327D] py-20 gap-y-8 flex-col justify-center':
                        deviceType === 'desktop',
                    'bg-[#B6327D] py-14 md:py-20 flex-col justify-between':
                        deviceType !== 'desktop',
                },
            )}
        >
            <div className="flex flex-end justify-end w-full">
                <img
                    className=" max-w-[160px] lg:max-w-[200px] w-full"
                    src={Logo}
                />
            </div>
            {deviceType === 'desktop' && (
                <div className="flex flex-col items-center justify-center w-full h-full p-[15%] min-w-[780px]">
                    <img className="w-full " src={SplashLockup} />
                    <DesktopSplash />
                </div>
            )}
            {deviceType === 'mobile' && (
                <div className="flex flex-col items-center justify-center flex-grow basis-1/2 h-full w-full overflow-visible pt-10">
                    <img
                        className="w-full max-w-[340px] lg:max-w-[400px] max-h-[300px] h-full"
                        src={MobileSplash}
                    />
                </div>
            )}
            {deviceType === 'loading' && (
                <div className="flex flex-col justify-center items-center px-10 pb-10 basis-1/2 h-full">
                    <Spinner width={50} height={50} />
                </div>
            )}
            {deviceType === 'mobile' && (
                <>
                    {permissionDenied ? (
                        <PermissionsDeniedScreen
                            permissionDenied={permissionDenied}
                        />
                    ) : (
                        <div
                            className={clsx(
                                'flex flex-col items-center flex-grow basis-1/2',
                                {
                                    'justify-end': uiType === 'default',
                                    'justify-center': uiType === 'loading',
                                },
                            )}
                        >
                            {uiType === 'loading' && (
                                <Spinner width={50} height={50} />
                            )}

                            {uiType === 'default' && (
                                <>
                                    <h2 className="text-lg md:text-xl font-light items-end text-center animate-fade-in font-mark-pro-xtralite pb-8 leading-[115%]">
                                        Access to your camera, microphone,
                                        <br />
                                        motion & orientation is required
                                    </h2>
                                </>
                            )}

                            {uiType !== 'loading' && (
                                <>
                                    <button
                                        className={clsx(
                                            'flex relative justify-center items-center font-button button-default',
                                            'animate-fade-in',
                                            'disabled:opacity-50',
                                        )}
                                        onClick={requestMotion}
                                        disabled={isLoadingExperience}
                                    >
                                        {isLoadingExperience ? (
                                            <span className="animate-fade-in">
                                                <Spinner
                                                    width={30}
                                                    height={30}
                                                />
                                            </span>
                                        ) : (
                                            <span className="relative font-roboto">
                                                BEGIN
                                            </span>
                                        )}
                                    </button>
                                </>
                            )}

                            {pageType === 'loading' && (
                                <>
                                    <div className="absolute bottom-0 mb-[5dvh]">
                                        <Logo8thWall />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

function DesktopSplash() {
    return (
        <div className="flex flex-row gap-[5%] justify-start items-center pl-[15.5%] -mt-[3%] w-full">
            <QrCodeGenerator
                value={window.location.href}
                fgColor="#B6327D"
                size={120}
            />
            <h2 className="text-left text-[22px] font-mark-pro-xtralite min-w-max leading-[26px]">
                Scan the QR code to launch the
                <br />
                Music off the Wall experience
            </h2>
        </div>
    );
}

type PermissionsDeniedScreenProps = {
    permissionDenied: 'camera-denied' | 'motion-denied' | undefined;
};
function PermissionsDeniedScreen({
    permissionDenied,
}: PermissionsDeniedScreenProps) {
    return (
        <div className="flex flex-col flex-grow justify-start gap-y-10 justify-center pt-6 items-center px-10 mb-12 basis-1/2">
            {permissionDenied === 'camera-denied' && (
                <>
                    <h2 className="font-mark-pro-xtralite text-xl text-center">
                        Camera access is required.
                    </h2>
                    <h2 className="font-mark-pro-xtralite text-xl text-center">
                        Quit and open the browser again.
                    </h2>
                </>
            )}
            {permissionDenied === 'motion-denied' && (
                <>
                    <h2 className="font-mark-pro-xtralite text-xl text-center ">
                        Motion and orientation access is required.
                    </h2>
                    <h2 className="font-mark-pro-xtralite text-xl text-center ">
                        Quit and open the browser again.
                    </h2>
                </>
            )}
        </div>
    );
}
