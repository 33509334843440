import {
    ImageTargetView,
    imageTargetViewAtom,
    imageTargetStore,
} from '../8thwall/atoms';

export class ImageTargetSwitcher {
    flipInterval = 2000;
    flip = false;
    numTargets = 14;
    targetId = 'none';
    constructor() {
        setInterval(() => this.handleMarkerSwitch(), this.flipInterval);
    }

    handleMarkerSwitch() {
        if (
            imageTargetStore.get(imageTargetViewAtom) !==
            ImageTargetView.SCANNING
        )
            return;
        const activeNums = [];
        const activeMarker =
            this.getTargetId === 'none' || this.getTargetId === undefined
                ? -1
                : Number(this.getTargetId.slice(-2));

        // preserve active page and neighbours
        if (activeMarker === -1 || activeMarker === 0) {
            activeNums.push(0, 1, 2);
        } else {
            activeNums.push(activeMarker, activeMarker - 1, activeMarker + 1);
        }

        // split remaining markers
        for (let i = 0; i <= this.numTargets; i++) {
            if ((i % 2 === 0) === this.flip && !activeNums.includes(i))
                activeNums.push(i);
        }

        const activeMarkers = activeNums.map(
            (num) => `music-centre-marker-${num.toString().padStart(2, '0')}`,
        );
        // console.log('active markers', activeMarkers)
        if (window.XR8) {
            XR8.XrController.configure({
                imageTargets: activeMarkers,
            });
        }

        // flip active half
        this.flip = !this.flip;
    }

    get getTargetId(): string {
        return this.targetId;
    }

    set setTargetId(id: string) {
        this.targetId = id;
    }
}
