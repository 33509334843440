import { IArtwork } from './types';

export const artworkData: IArtwork[] = [
    {
        status: 'published',
        created: '2021-10-18T04:30:35.880Z',
        viewsCount: 1046,
        targetName: 'music-centre-marker-00',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-01d0d010-6816-4afa-a972-a9605fdcf077',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 567,
        sortkey: 'Artwork',
        artist: 'MARISSA NOANA',
        animator: 'MARISSA NOANA',
        soundDesigner: 'TOBACCO RAT',
        instagram: 'https://www.instagram.com/marissanoana',
        imageSquare: '/images-square/00.webp',
        files: [
            {
                checksum: 'ea3c517f8e323948fdd954aafe6e6466c603361e',
                width: 512,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077-artwork512.jpg',
                size: 31390,
                type: 'artwork512',
                height: 511,
            },
            {
                checksum: '985c38a6657092cb71e5a181a6b18a6893353b50',
                width: 1024,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077-artwork1024.jpg',
                size: 110131,
                type: 'artwork1024',
                height: 1022,
            },
            {
                checksum: 'dc5ca7343fd8195da3cfd172fd2047ba1ee33248',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-2fc1df75-9959-490e-b878-a6a7aea1fba4/Artwork-2fc1df75-9959-490e-b878-a6a7aea1fba4-qr.png',
                size: 1191,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: 'd6908c1fc0370f1c4660b2ae47b813e489c354f1',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077-animation.mp4',
                size: 6772945,
                type: 'animation',
                height: 2044,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: false,
        viewsSeconds: 15774,
        modified: '2023-12-27T16:12:53.138Z',
        type: 'artwork',
        id: '01d0d010-6816-4afa-a972-a9605fdcf077',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077-qr-code.png',
            },
            animation: {
                checksum: 'd6908c1fc0370f1c4660b2ae47b813e489c354f1',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077-animation.mp4',
                size: 6772945,
                type: 'animation',
                height: 2044,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077/Artwork-01d0d010-6816-4afa-a972-a9605fdcf077-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-10-18T03:34:25.653Z',
        viewsCount: 1806,
        targetName: 'music-centre-marker-12',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 685,
        sortkey: 'Artwork',
        artist: 'SA1NTDENIS',
        animator: 'SA1NTDENIS',
        soundDesigner: 'M1N0T0R',
        instagram: 'https://www.instagram.com/sa1ntdenis',
        imageSquare: '/images-square/12.webp',
        files: [
            {
                checksum: '996a3fc3f93c9d705c6d144a98beb266121e99e4',
                width: 512,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea-artwork512.jpg',
                size: 35118,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: '29db19face1fb97a5999d3e25cccd2a2bf46b27f',
                width: 1024,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea-artwork1024.jpg',
                size: 92155,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: 'f23a8d2c503ae29d829c661cfc67a7ff601fc4b1',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-05826b29-ff85-491d-89f4-d7542281bc95/Artwork-05826b29-ff85-491d-89f4-d7542281bc95-qr.png',
                size: 1181,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: '1d8b23582caa25678aa90eb20695f9cbd10ce8f0',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea-animation.mp4',
                size: 2085407,
                type: 'animation',
                height: 2048,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 125436,
        modified: '2024-02-10T08:45:14.172Z',
        type: 'artwork',
        id: '151c6bbe-8f7c-4d76-9721-928ed45bcaea',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea-qr-code.png',
            },
            animation: {
                checksum: '1d8b23582caa25678aa90eb20695f9cbd10ce8f0',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea-animation.mp4',
                size: 2085407,
                type: 'animation',
                height: 2048,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea/Artwork-151c6bbe-8f7c-4d76-9721-928ed45bcaea-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-11-01T07:28:51.890Z',
        viewsCount: 2813,
        targetName: 'music-centre-marker-03',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 706,
        sortkey: 'Artwork',
        artist: 'SUTU',
        animator: 'SUTU',
        soundDesigner: 'TOBACCO RAT',
        instagram: 'https://www.instagram.com/sutu.eats.flies',
        imageSquare: '/images-square/03.webp',
        files: [
            {
                checksum: '6a5a3327afe3f84926d847fb3fe6a045e2013bea',
                width: 396,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b-artwork512.jpg',
                size: 18981,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: '8b51b851304bf7591717b9196fb581ac5b701475',
                width: 791,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b-artwork1024.jpg',
                size: 54464,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: '466664fdb94cf0759e5a55e3fd4dee2fbb904bc4',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-265f0fcb-8b63-4185-941a-e1544b218ea7/Artwork-265f0fcb-8b63-4185-941a-e1544b218ea7-qr.png',
                size: 1182,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: '0c353493d3921c64a04644ec3341fcc666684336',
                width: 1582,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b-animation.mp4',
                size: 2464088,
                type: 'animation',
                height: 2048,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 43393,
        modified: '2024-02-15T23:50:55.142Z',
        type: 'artwork',
        id: 'b8e6f0fa-819f-40ab-96f8-35ef85b7002b',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b-qr-code.png',
            },
            animation: {
                checksum: '0c353493d3921c64a04644ec3341fcc666684336',
                width: 1582,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b-animation.mp4',
                size: 2464088,
                type: 'animation',
                height: 2048,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b/Artwork-b8e6f0fa-819f-40ab-96f8-35ef85b7002b-qr-code.png',
        class: null,
        version: 3,
        userData: {
            userid: 'd613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
            webarEnabled: true,
        },
    },
    {
        status: 'published',
        created: '2020-07-01T00:56:56.169Z',
        viewsCount: 1597,
        targetName: 'music-centre-marker-06',
        enabled: true,
        partitionkey: 'Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18',
        publishEnabled: true,
        creatorVersion: '0.1.17',
        downloadsCount: 773,
        sortkey: 'Artwork',
        artist: 'MICHAEL TAN',
        animator: 'MICHAEL TAN',
        soundDesigner: 'MICHAEL TAN',
        instagram: 'https://www.instagram.com/michaeltan___',
        imageSquare: '/images-square/06.webp',
        files: [
            {
                checksum: 'b5d73f6d08aae4bdd87561bb1c6566e5bd769630',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18-artwork512.jpg',
                size: 40667,
                type: 'artwork512',
            },
            {
                checksum: 'f2a828e0cd8b09970ee3f5dc5dcae9b5d9519cae',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18-artwork1024.jpg',
                size: 179062,
                type: 'artwork1024',
            },
            {
                checksum: '89536be5757cd4a57a498f86eb9036887d48c7cf',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18-animation.mp4',
                size: 3545037,
                type: 'animation',
            },
            {
                checksum: '59b799aeb4c6ccef741bbd7957795a3b0e729ecf',
                path: 'https://cdn.eyejackapp.com/art/prd/v1/652c3620-d689-11e8-828a-1334c9ae6ae8/652c3620-d689-11e8-828a-1334c9ae6ae8-qr.png',
                size: 1397,
                type: 'qrCode',
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 15770,
        modified: '2024-02-13T00:24:45.088Z',
        type: 'artwork',
        id: '239335e2-33bf-4aa9-8feb-7a8f20fe7d18',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18-artwork1024.jpg',
            },
            animation: {
                checksum: '89536be5757cd4a57a498f86eb9036887d48c7cf',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18-animation.mp4',
                size: 3545037,
                type: 'animation',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18-qr-code.png',
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18/Artwork-239335e2-33bf-4aa9-8feb-7a8f20fe7d18-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2020-07-01T00:55:04.226Z',
        viewsCount: 2070,
        targetName: 'music-centre-marker-07',
        enabled: true,
        partitionkey: 'Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d',
        publishEnabled: true,
        creatorVersion: '0.1.17',
        downloadsCount: 950,
        sortkey: 'Artwork',
        artist: 'DAVE MUTNJAKOVIC',
        animator: 'DAVE MUTNJAKOVIC',
        soundDesigner: 'ALEKSANDAR PREMRL',
        instagram: 'https://www.instagram.com/dejv__m',
        imageSquare: '/images-square/07.webp',
        files: [
            {
                checksum: '528ada41480ce31a16cc76709380e2ee6211cc74',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d-artwork512.jpg',
                size: 52702,
                type: 'artwork512',
            },
            {
                checksum: 'fa7cdec794135882b8631b6917b6d0339a6518e6',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d-artwork1024.jpg',
                size: 170682,
                type: 'artwork1024',
            },
            {
                checksum: '8603a6c335ab3f309ad3bb95ce07d512cf1ed406',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d-animation.mp4',
                size: 5068128,
                type: 'animation',
            },
            {
                checksum: '9d3096708724b62767374c866cd37c7a28db672a',
                path: 'https://cdn.eyejackapp.com/art/prd/v1/80b56260-d687-11e8-828a-1334c9ae6ae8/80b56260-d687-11e8-828a-1334c9ae6ae8-qr.png',
                size: 1389,
                type: 'qrCode',
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 42680,
        modified: '2024-02-13T00:22:13.905Z',
        type: 'artwork',
        id: '323a2036-585d-4f4f-9c1d-515fcf28b92d',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d-artwork1024.jpg',
            },
            animation: {
                checksum: '8603a6c335ab3f309ad3bb95ce07d512cf1ed406',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d-animation.mp4',
                size: 5068128,
                type: 'animation',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d-qr-code.png',
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d/Artwork-323a2036-585d-4f4f-9c1d-515fcf28b92d-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-10-18T02:39:49.009Z',
        viewsCount: 1439,
        targetName: 'music-centre-marker-13',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 501,
        sortkey: 'Artwork',
        artist: 'MOBOTATO',
        animator: 'MOBOTATO',
        soundDesigner: 'FREESOUND',
        instagram: 'https://www.instagram.com/mobotato',
        imageSquare: '/images-square/13.webp',
        files: [
            {
                checksum: '0cc3507137e9e97121ff2737f5a53e34d4bdf42c',
                width: 512,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad-artwork512.jpg',
                size: 36230,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: '1a33e0a28db6aee1537bd5420424cebeba52cc93',
                width: 1024,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad-artwork1024.jpg',
                size: 100600,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: 'b949fec487f3e2ece2e9226fdf602b3595ae4ecf',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-e6c0816c-9511-4aae-a339-0c2df70df1a5/Artwork-e6c0816c-9511-4aae-a339-0c2df70df1a5-qr.png',
                size: 1190,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: 'b99999208fd85ba741ba3fc5e9d59a6ea9defdc5',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad-animation.mp4',
                size: 2881218,
                type: 'animation',
                height: 2048,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 26979,
        modified: '2024-02-10T08:46:42.432Z',
        type: 'artwork',
        id: '3512e8a2-7fda-432a-b1d2-d934d9cba5ad',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad-qr-code.png',
            },
            animation: {
                checksum: 'b99999208fd85ba741ba3fc5e9d59a6ea9defdc5',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad-animation.mp4',
                size: 2881218,
                type: 'animation',
                height: 2048,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad/Artwork-3512e8a2-7fda-432a-b1d2-d934d9cba5ad-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2022-03-01T23:56:22.640Z',
        viewsCount: 32,
        targetName: 'music-centre-marker-10',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 5,
        sortkey: 'Artwork',
        artist: 'GARRETT HOYOS',
        animator: 'GARRETT HOYOS',
        soundDesigner: 'GARRETT HOYOS',
        instagram: 'https://www.instagram.com/garretthoyos',
        imageSquare: '/images-square/10.webp',
        files: [
            {
                checksum: '8f60d8e41a1509cb231d4f3dc5ff0571bed394cc',
                width: 512,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1-artwork512.jpg',
                size: 38115,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: '6486da97901685b60bc6721a4a3ec51a6a4d85ce',
                width: 1024,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1-artwork1024.jpg',
                size: 107762,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: '8b65e112ca8af1481340e78df31fe9f84c7d124a',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-2ca4ad98-9d4c-4697-998e-87b5d6928581/Artwork-2ca4ad98-9d4c-4697-998e-87b5d6928581-qr.png',
                size: 1188,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: '7f71670822f5679c5cb4cf781b5abe53cabe93d4',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1-animation.mp4',
                size: 3464974,
                type: 'animation',
                height: 2048,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 194,
        modified: '2022-09-19T09:34:57.236Z',
        type: 'artwork',
        id: '4d4b86a2-ed00-479f-b9a5-2795e242b1e1',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1-qr-code.png',
            },
            animation: {
                checksum: '7f71670822f5679c5cb4cf781b5abe53cabe93d4',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1-animation.mp4',
                size: 3464974,
                type: 'animation',
                height: 2048,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1/Artwork-4d4b86a2-ed00-479f-b9a5-2795e242b1e1-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-10-20T05:42:54.030Z',
        viewsCount: 2517,
        targetName: 'music-centre-marker-02',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 942,
        sortkey: 'Artwork',
        artist: 'GALAMOT & NAVA',
        animator: 'GALAMOT',
        soundDesigner: 'AMANTES DEL FUTURO',
        instagram: 'https://www.instagram.com/galamot',
        imageSquare: '/images-square/02.webp',
        files: [
            {
                checksum: '19e2d3c5b7fa34c5a16c611639f8b04cd054e9c2',
                width: 365,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae-artwork512.jpg',
                size: 75699,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: 'fd6d0556e6e76f136809fe24da119bbbf2dd009b',
                width: 729,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae-artwork1024.jpg',
                size: 216507,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: '3f0f3801561cf2f86634a752a49c5abf4b7b4afc',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-5dfdd996-62cd-4ade-bd40-45866a5c722a/Artwork-5dfdd996-62cd-4ade-bd40-45866a5c722a-qr.png',
                size: 1184,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: '2974f61a2e1dee6f0100185c623a4f495858ea87',
                width: 1458,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae-animation.mp4',
                size: 6364222,
                type: 'animation',
                height: 2048,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 42729,
        modified: '2024-02-10T08:42:44.796Z',
        type: 'artwork',
        id: '4deebccb-c77d-410d-aa6a-57049aef08ae',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae-qr-code.png',
            },
            animation: {
                checksum: '2974f61a2e1dee6f0100185c623a4f495858ea87',
                width: 1458,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae-animation.mp4',
                size: 6364222,
                type: 'animation',
                height: 2048,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae/Artwork-4deebccb-c77d-410d-aa6a-57049aef08ae-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2020-07-01T00:56:28.827Z',
        viewsCount: 968,
        targetName: 'music-centre-marker-05',
        enabled: true,
        partitionkey: 'Artwork-603a5413-5136-4c46-ba87-22edaede3dc9',
        publishEnabled: true,
        creatorVersion: '0.1.17',
        downloadsCount: 479,
        sortkey: 'Artwork',
        artist: 'DANIEL WESSEIK',
        animator: 'DANIEL WESSEIK',
        soundDesigner: 'RICH BESSELL',
        instagram: 'http://www.instagram.com/danielwesseik',
        imageSquare: '/images-square/05.webp',
        files: [
            {
                checksum: '5a87c52f104ed4f7fa3d5eb31f20be75120702a2',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9-artwork512.jpg',
                size: 35157,
                type: 'artwork512',
            },
            {
                checksum: '078c628aed356598b1d0da3a2d1bba92c160c673',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9-artwork1024.jpg',
                size: 126759,
                type: 'artwork1024',
            },
            {
                checksum: '839676e760ffc47f8aa94151ed803cdb708ebc78',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9-animation.mp4',
                size: 2050681,
                type: 'animation',
            },
            {
                checksum: 'ea42018a9cebab3bdf92213f3a1d275e43a4c78d',
                path: 'https://cdn.eyejackapp.com/art/prd/v1/68027280-d687-11e8-828a-1334c9ae6ae8/68027280-d687-11e8-828a-1334c9ae6ae8-qr.png',
                size: 1371,
                type: 'qrCode',
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 11731,
        modified: '2024-02-09T10:17:52.564Z',
        type: 'artwork',
        id: '603a5413-5136-4c46-ba87-22edaede3dc9',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9-artwork1024.jpg',
            },
            animation: {
                checksum: '839676e760ffc47f8aa94151ed803cdb708ebc78',
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9-animation.mp4',
                size: 2050681,
                type: 'animation',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9-qr-code.png',
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9/Artwork-603a5413-5136-4c46-ba87-22edaede3dc9-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-10-18T04:33:03.182Z',
        viewsCount: 1747,
        targetName: 'music-centre-marker-14',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 707,
        sortkey: 'Artwork',
        artist: 'UNARANETA',
        animator: 'UNARANETA',
        soundDesigner: 'THAN',
        instagram: 'https://www.instagram.com/unaraneta',
        imageSquare: '/images-square/14.webp',
        files: [
            {
                checksum: '542f3da745b087991d15f11160cc17a5f40f2b13',
                width: 512,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26-artwork512.jpg',
                size: 41084,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: '625b8ed31ec7c02230a2f97b9d28ca37ebc21490',
                width: 1024,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26-artwork1024.jpg',
                size: 125505,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: '2eb23fabafb98614bffd06508cce717105a80176',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-01931476-491c-46a2-aea4-6cc6b811f68e/Artwork-01931476-491c-46a2-aea4-6cc6b811f68e-qr.png',
                size: 1183,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: '87e3c9f3f649e621c135f8704e69f0f558c3c9a5',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26-animation.mp4',
                size: 9215535,
                type: 'animation',
                height: 2048,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: false,
        viewsSeconds: 33268,
        modified: '2024-01-30T14:59:42.899Z',
        type: 'artwork',
        id: '99ae349b-f4cd-49e3-88b3-3542b1a36e26',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26-qr-code.png',
            },
            animation: {
                checksum: '87e3c9f3f649e621c135f8704e69f0f558c3c9a5',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26-animation.mp4',
                size: 9215535,
                type: 'animation',
                height: 2048,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26/Artwork-99ae349b-f4cd-49e3-88b3-3542b1a36e26-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-10-18T10:35:20.204Z',
        viewsCount: 1599,
        targetName: 'music-centre-marker-04',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 669,
        sortkey: 'Artwork',
        artist: 'THE GLAD SCIENTIST',
        animator: 'THE GLAD SCIENTIST',
        soundDesigner: 'THE GLAD SCIENTIST',
        instagram: 'https://www.instagram.com/thegladscientist',
        imageSquare: '/images-square/04.webp',
        files: [
            {
                checksum: '77ab52e0e0838cbab809394104df75325d801347',
                width: 512,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a-artwork512.jpg',
                size: 35879,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: 'c888b8cef28188d304f8db1e0801533d36204a24',
                width: 1024,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a-artwork1024.jpg',
                size: 101957,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: '1d5821cec1ee30b0aedf8e5a37893baeac013e37',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-960fabef-97a1-44ca-9d89-9e044d52d553/Artwork-960fabef-97a1-44ca-9d89-9e044d52d553-qr.png',
                size: 1176,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: 'fd9fa662c3ce36b240db5ead2d98cc8f92144f12',
                width: 4000,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a-animation.mp4',
                size: 9598146,
                type: 'animation',
                height: 4000,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: false,
        viewsSeconds: 21792,
        modified: '2024-02-10T08:46:05.605Z',
        type: 'artwork',
        id: 'b5fcad15-ae07-4b31-af97-4cc39836189a',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a-qr-code.png',
            },
            animation: {
                checksum: 'fd9fa662c3ce36b240db5ead2d98cc8f92144f12',
                width: 4000,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a-animation.mp4',
                size: 9598146,
                type: 'animation',
                height: 4000,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a/Artwork-b5fcad15-ae07-4b31-af97-4cc39836189a-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-10-18T21:47:39.469Z',
        viewsCount: 1378,
        targetName: 'music-centre-marker-09',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 532,
        sortkey: 'Artwork',
        artist: 'JAKE GUMBLETON',
        animator: 'JAKE GUMBLETON',
        soundDesigner: 'ARRBEE SOUND',
        instagram: 'https://www.instagram.com/jakegumbletonart',
        imageSquare: '/images-square/09.webp',
        files: [
            {
                checksum: 'eeed3934049ddebb35ba50a79b018e13e6694478',
                width: 404,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc-artwork512.jpg',
                size: 59438,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: '4e9d83aa0e28545a3acdda27756a29e51f2321de',
                width: 808,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc-artwork1024.jpg',
                size: 203392,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: '95d004674073c9119d77710174060b7a428123ec',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-e975f288-1540-4d24-aeeb-e331100f917e/Artwork-e975f288-1540-4d24-aeeb-e331100f917e-qr.png',
                size: 1189,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: 'ee1b80863ac2c831d7d1678e48cb350fd4e6e20f',
                width: 1616,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc-animation.mp4',
                size: 6197681,
                type: 'animation',
                height: 2048,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 59649,
        modified: '2024-02-08T05:37:46.695Z',
        type: 'artwork',
        id: 'ccf7ee23-e044-48bd-990b-7bb6611a9cdc',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc-qr-code.png',
            },
            animation: {
                checksum: 'ee1b80863ac2c831d7d1678e48cb350fd4e6e20f',
                width: 1616,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc-animation.mp4',
                size: 6197681,
                type: 'animation',
                height: 2048,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc/Artwork-ccf7ee23-e044-48bd-990b-7bb6611a9cdc-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-10-18T01:38:18.175Z',
        viewsCount: 906,
        targetName: 'music-centre-marker-11',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-dada289e-bda9-4478-aee7-20a1d3370edd',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 417,
        sortkey: 'Artwork',
        artist: 'THÖR',
        animator: 'THÖR',
        soundDesigner: 'ARRBEE SOUND',
        instagram: 'https://instagram.com/vj.thor',
        imageSquare: '/images-square/11.webp',
        files: [
            {
                checksum: '7e1f73d2047105b94ad57ec769abb56ba3543ea6',
                width: 512,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd-artwork512.jpg',
                size: 31931,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: '1d019bdb8d0f1e389eda661c7eceb53b5e0426cd',
                width: 1024,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd-artwork1024.jpg',
                size: 78186,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: '504435fe8d1eaea4176b63fd2b799e8da8d97a75',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-147a8d5a-55df-4ce7-8cd5-9c76112676c0/Artwork-147a8d5a-55df-4ce7-8cd5-9c76112676c0-qr.png',
                size: 1191,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: 'd5a11a6d058076cf686dead868069514a5598475',
                width: 2222,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd-animation.mp4',
                size: 4810923,
                type: 'animation',
                height: 2222,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 10421,
        modified: '2024-02-06T23:39:14.166Z',
        type: 'artwork',
        id: 'dada289e-bda9-4478-aee7-20a1d3370edd',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd-qr-code.png',
            },
            animation: {
                checksum: 'd5a11a6d058076cf686dead868069514a5598475',
                width: 2222,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd-animation.mp4',
                size: 4810923,
                type: 'animation',
                height: 2222,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd/Artwork-dada289e-bda9-4478-aee7-20a1d3370edd-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-10-18T04:49:07.614Z',
        viewsCount: 604,
        targetName: 'music-centre-marker-08',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b',
        publishEnabled: true,
        creatorVersion: '3.3.5',
        downloadsCount: 347,
        sortkey: 'Artwork',
        artist: 'DANIEL BREDA',
        animator: 'DANIEL BREDA',
        soundDesigner: 'JAMES ANDREWS',
        instagram: 'https://www.instagram.com/dj.bredaart',
        imageSquare: '/images-square/08.webp',
        files: [
            {
                checksum: 'c740a767d38ec89cbf059b9264faacfefc0760cf',
                width: 307,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-artwork512.jpg',
                size: 29226,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: 'f032ef48c5fcd6c1e34fa7bd666fd7f0da02e4c1',
                width: 614,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-artwork1024.jpg',
                size: 80034,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: '00e8f99205ce5ce0d14e3f57165d31a973a493e7',
                width: 1500,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-webarTarget.jpg',
                size: 210760,
                type: 'webarTarget',
                height: 2000,
            },
            {
                checksum: 'd2bc2a590f070d8bf1cd6b20e7da1633aeafed70',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-33f3717f-4d26-4373-9c6b-e0cd4045b873/Artwork-33f3717f-4d26-4373-9c6b-e0cd4045b873-qr.png',
                size: 1181,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: 'c5b669624dda48b96bcc795491564b34db532945',
                width: 1228,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-animation.mp4',
                size: 9923743,
                type: 'animation',
                height: 2048,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 7900,
        modified: '2024-02-13T00:49:15.795Z',
        type: 'artwork',
        id: 'ef42e9ac-43e1-4f28-b450-953c4f677a2b',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-artwork1024.jpg',
            },
            webarTarget: {
                checksum: '00e8f99205ce5ce0d14e3f57165d31a973a493e7',
                width: 1500,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-webarTarget.jpg',
                size: 210760,
                type: 'webarTarget',
                height: 2000,
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-qr-code.png',
            },
            animation: {
                checksum: 'c5b669624dda48b96bcc795491564b34db532945',
                width: 1228,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-animation.mp4',
                size: 9923743,
                type: 'animation',
                height: 2048,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b/Artwork-ef42e9ac-43e1-4f28-b450-953c4f677a2b-qr-code.png',
        class: null,
        version: 3,
    },
    {
        status: 'published',
        created: '2021-10-18T03:53:26.507Z',
        viewsCount: 1533,
        targetName: 'music-centre-marker-01',
        artworkType: 'imageTarget',
        enabled: true,
        partitionkey: 'Artwork-fd43e494-5885-4076-9c11-471357b1faab',
        publishEnabled: true,
        creatorVersion: '3.3.2',
        downloadsCount: 690,
        sortkey: 'Artwork',
        artist: 'HELENA PAPAGEORGIOU',
        animator: 'HELENA PAPAGEORGIOU',
        soundDesigner: 'LUPUS NOCTE',
        instagram: 'https://www.instagram.com/helenapapageorgiou_',
        imageSquare: '/images-square/01.webp',
        files: [
            {
                checksum: 'dd56fc20dbeeb4ad2dc427bf6c218ff40b192bfa',
                width: 512,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork512.jpg',
                size: 36476,
                type: 'artwork512',
                height: 512,
            },
            {
                checksum: '993a033ac471815f236b37de071f249a62c069f9',
                width: 1024,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork1024.jpg',
                size: 145404,
                type: 'artwork1024',
                height: 1024,
            },
            {
                checksum: 'b2fba831f14734a9004da0adfc60e90c029b0b5f',
                width: 410,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-d33f7456-bdec-47a3-8fa6-baa4bbd36c98/Artwork-d33f7456-bdec-47a3-8fa6-baa4bbd36c98-qr.png',
                size: 1179,
                type: 'qrCode',
                height: 410,
            },
            {
                checksum: 'aa14c269a39ac11c38719f3d107ed7e261a72edf',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-animation.mp4',
                size: 6940622,
                type: 'animation',
                height: 2048,
            },
        ],
        data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
        animationHasAlpha: true,
        viewsSeconds: 26215,
        modified: '2024-01-31T20:14:18.087Z',
        type: 'artwork',
        id: 'fd43e494-5885-4076-9c11-471357b1faab',
        images: {
            artwork512: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork1024.jpg',
            },
            artwork1024: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork1024.jpg',
            },
            qrCode: {
                path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-qr-code.png',
            },
            animation: {
                checksum: 'aa14c269a39ac11c38719f3d107ed7e261a72edf',
                width: 2048,
                path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-animation.mp4',
                size: 6940622,
                type: 'animation',
                height: 2048,
            },
        },
        isWorldTarget: false,
        constructedCover:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork1024.jpg',
        constructedQR:
            'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-qr-code.png',
        class: null,
        version: 3,
    },
];

export const modelArtwork: IArtwork = {
    status: 'published',
    created: '2021-10-18T03:53:26.507Z',
    viewsCount: 1533,
    targetName: 'music-centre-marker-01',
    artworkType: 'imageTarget',
    enabled: true,
    partitionkey: 'Artwork-fd43e494-5885-4076-9c11-471357b1faab',
    publishEnabled: true,
    creatorVersion: '3.3.2',
    downloadsCount: 690,
    sortkey: 'Artwork',
    artist: 'PANDAKERO',
    animator: 'PANDAKERO',
    creativeDirection: 'SUTU',
    soundDesigner: 'RISIAN',
    instagram: 'https://instagram.com/pandakero',
    imageSquare: '/images-square/3d-model.webp',
    files: [
        {
            checksum: 'dd56fc20dbeeb4ad2dc427bf6c218ff40b192bfa',
            width: 512,
            path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork512.jpg',
            size: 36476,
            type: 'artwork512',
            height: 512,
        },
        {
            checksum: '993a033ac471815f236b37de071f249a62c069f9',
            width: 1024,
            path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork1024.jpg',
            size: 145404,
            type: 'artwork1024',
            height: 1024,
        },
        {
            checksum: 'b2fba831f14734a9004da0adfc60e90c029b0b5f',
            width: 410,
            path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-d33f7456-bdec-47a3-8fa6-baa4bbd36c98/Artwork-d33f7456-bdec-47a3-8fa6-baa4bbd36c98-qr.png',
            size: 1179,
            type: 'qrCode',
            height: 410,
        },
        {
            checksum: 'aa14c269a39ac11c38719f3d107ed7e261a72edf',
            width: 2048,
            path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-animation.mp4',
            size: 6940622,
            type: 'animation',
            height: 2048,
        },
    ],
    data: 'User-d613bb1a-8563-4ef3-aeb1-48ff95cb7d6e',
    animationHasAlpha: true,
    viewsSeconds: 26215,
    modified: '2024-01-31T20:14:18.087Z',
    type: 'artwork',
    id: 'fd43e494-5885-4076-9c11-471357b1faab',
    images: {
        artwork512: {
            path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork1024.jpg',
        },
        artwork1024: {
            path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork1024.jpg',
        },
        qrCode: {
            path: 'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-qr-code.png',
        },
        animation: {
            checksum: 'aa14c269a39ac11c38719f3d107ed7e261a72edf',
            width: 2048,
            path: 'https://cdn.eyejackapp.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-animation.mp4',
            size: 6940622,
            type: 'animation',
            height: 2048,
        },
    },
    isWorldTarget: false,
    constructedCover:
        'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-artwork1024.jpg',
    constructedQR:
        'https://eyejack-art.s3.ap-southeast-2.amazonaws.com/v3/prd/artworks/Artwork-fd43e494-5885-4076-9c11-471357b1faab/Artwork-fd43e494-5885-4076-9c11-471357b1faab-qr-code.png',
    class: null,
    version: 3,
};
